import { AuthGuard } from 'src/auth/guard';
import { Outlet } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import Redirecting from '../components/redirecting';

const NotFoundView = lazy(() => import('src/views/error/NotFoundView'));
const DashboardPage = lazy(() => import('src/views/dashboard/DashboardPage'));

const ClientListPage = lazy(() => import('src/views/client/list/ClientListPage'));
const ClientDetailPage = lazy(() => import('src/views/client/detail/ClientDetailPage'));

const ProviderListPage = lazy(() => import('src/views/provider/list/ProviderListPage'));
const ProviderDetailPage = lazy(() => import('src/views/provider/detail/ProviderDetailPage'));
const PricingGroupListPage = lazy(
  () => import('src/views/provider/pricingGroup/ProviderPricingGroupListPage')
);
const ProfessionalGroupListPage = lazy(
  () => import('src/views/provider/professionalGroup/list/ProfessionalGroupListPage')
);
const ProfessionalGroupDetailPage = lazy(
  () => import('src/views/provider/professionalGroup/detail/ProfessionalGroupDetailPage')
);

const ChatSessionListPage = lazy(() => import('src/views/messages/list/MessagesListPage'));

const MeetingListPage = lazy(() => import('src/views/meeting/list/MeetingListPage'));
const MeetingDetailPage = lazy(() => import('src/views/meeting/detail/MeetingDetailPage'));
const MeetingPackageOptionsListPage = lazy(
  () => import('src/views/plan/packageOptions/list/MeetingPackageOptionsListPage')
);
const MeetingPackageOptionDetailPage = lazy(
  () => import('src/views/plan/packageOptions/detail/MeetingPackageOptionDetailPage')
);
const MeetingPackageOptionCreatePage = lazy(
  () => import('src/views/plan/packageOptions/detail/MeetingPackageOptionCreatePage')
);

const UserPlanPrototypeListPage = lazy(
  () => import('src/views/plan/userPlanPrototypes/list/UserPlanPrototypesListPage')
);
const UserPlanPrototypeDetail = lazy(
  () => import('src/views/plan/userPlanPrototypes/detail/UserPlanPrototypeDetail')
);
const UserPlanPrototypeCreatePage = lazy(
  () => import('src/views/plan/userPlanPrototypes/detail/UserPlanPrototypeCreatePage')
);

const VerticalPlanPrototypesListPage = lazy(
  () => import('src/views/plan/verticalPlanPrototypes/list/VerticalPlanPrototypesListPage')
);
const VerticalPlanPrototypeDetailPage = lazy(
  () => import('src/views/plan/verticalPlanPrototypes/detail/VerticalPlanPrototypeDetailPage')
);
const VerticalPlanPrototypeCreatePage = lazy(
  () => import('src/views/plan/verticalPlanPrototypes/detail/VerticalPlanPrototypeCreatePage')
);

const SponsorListPage = lazy(() => import('src/views/sponsor/list/SponsorListPage'));
const SponsorDetailPage = lazy(() => import('src/views/sponsor/detail/SponsorDetailPage'));

const ReconciliationListPage = lazy(
  () => import('src/views/reconciliation/list/ReconciliationListPage')
);
const ReconciliationDetailPage = lazy(
  () => import('src/views/reconciliation/detail/ReconciliationDetailPage')
);

const ProviderCompensationListPage = lazy(
  () => import('src/views/providerCompensation/list/ProviderCompensationListPage')
);
const ProviderCompensationDetailPage = lazy(
  () => import('src/views/providerCompensation/detail/ProviderCompensationDetailPage')
);

const InvoiceListPage = lazy(() => import('src/views/invoice/list/InvoiceListPage'));
const InvoiceDetailPage = lazy(() => import('src/views/invoice/detail/InvoiceDetailPage'));

const EventListPage = lazy(() => import('src/views/event/list/EventListPage'));
const EventDetailPage = lazy(() => import('src/views/event/detail/EventDetailPage'));
const CreateEventPage = lazy(() => import('src/views/event/modify/CreateEventPage'));
const EventCopyDetailPage = lazy(() => import('src/views/event/copy/EventCopyDetailPage'));
const SponsorshipListPage = lazy(() => import('src/views/sponsorship/list/SponsorshipListPage'));
const SponsorshipDetailPage = lazy(
  () => import('src/views/sponsorship/detail/SponsorshipDetailPage')
);
const SponsorshipCreatePage = lazy(
  () => import('src/views/sponsorship/detail/SponsorshipCreatePage')
);

const RemoteConfigListPage = lazy(() => import('src/views/remoteConfig/list/RemoteConfigListPage'));
const RemoteConfigDetailPage = lazy(
  () => import('src/views/remoteConfig/detail/RemoteConfigDetailPage')
);
const RemoteConfigCreatePage = lazy(
  () => import('src/views/remoteConfig/detail/RemoteConfigCreatePage')
);

const RemoteConfigAssignorListPage = lazy(
  () => import('src/views/remoteConfig/assignor/list/RemoteConfigAssignorListPage')
);
const RemoteConfigAssignorDetailPage = lazy(
  () => import('src/views/remoteConfig/assignor/detail/RemoteConfigAssignorDetailPage')
);

const RemoteConfigAssignorCreatePage = lazy(
  () => import('src/views/remoteConfig/assignor/detail/RemoteConfigAssignorCreatePage')
);

const DonationProductsListPage = lazy(
  () => import('src/views/plan/donationProducts/list/DonationProductsListPage')
);
const DonationProductDetailPage = lazy(
  () => import('src/views/plan/donationProducts/detail/DonationProductDetailPage')
);
const DonationProductCreatePage = lazy(
  () => import('src/views/plan/donationProducts/detail/DonationProductCreatePage')
);

const MarketplaceProductGroupListPage = lazy(
  () => import('src/views/plan/marketplaceProductGroups/list/MarketplaceProductGroupListPage')
);
const MarketplaceProductGroupDetailPage = lazy(
  () => import('src/views/plan/marketplaceProductGroups/detail/MarketplaceProductGroupDetailPage')
);
const MarketplaceProductGroupCreatePage = lazy(
  () => import('src/views/plan/marketplaceProductGroups/detail/MarketplaceProductGroupCreatePage')
);

const AccrualOptionsListPage = lazy(
  () => import('src/views/plan/accrualOptions/list/AccrualOptionsListPage')
);
const AccrualOptionDetailPage = lazy(
  () => import('src/views/plan/accrualOptions/detail/AccrualOptionDetailPage')
);
const AccrualOptionCreatePage = lazy(
  () => import('src/views/plan/accrualOptions/detail/AccrualOptionCreatePage')
);

const AccrualOptionGroupsListPage = lazy(
  () => import('src/views/plan/accrualOptionGroups/list/AccrualOptionGroupsListPage')
);
const AccrualOptionGroupDetailPage = lazy(
  () => import('src/views/plan/accrualOptionGroups/detail/AccrualOptionGroupDetailPage')
);
const AccrualOptionGroupCreatePage = lazy(
  () => import('src/views/plan/accrualOptionGroups/detail/AccrualOptionGroupCreatePage')
);

export const kerberosRoutes = [
  {
    children: [
      { element: <DashboardPage />, path: 'dashboard' },
      {
        children: [
          { element: <ClientListPage />, index: true, path: 'list' },
          { element: <ClientDetailPage />, path: ':id' }
        ],
        path: 'client'
      },
      {
        children: [
          { element: <ProviderListPage />, index: true, path: 'list' },
          { element: <ProviderDetailPage />, path: ':id' },
          { element: <PricingGroupListPage />, path: 'pricing-groups' },
          { element: <ProfessionalGroupListPage />, path: 'professional-groups/list' },
          { element: <ProfessionalGroupDetailPage />, path: 'professional-groups/:id' }
        ],
        path: 'provider'
      },
      {
        children: [
          { element: <ChatSessionListPage />, index: true },
          { element: <ChatSessionListPage />, path: 'list' }
        ],
        path: 'messages'
      },
      {
        children: [
          { element: <MeetingListPage />, index: true, path: 'list' },
          { element: <MeetingDetailPage />, path: ':id' }
        ],
        path: 'meetings'
      },
      {
        children: [
          {
            element: <MeetingPackageOptionsListPage />,
            index: true,
            path: 'meeting-package-options/list'
          },
          {
            element: <MeetingPackageOptionCreatePage />,
            path: 'meeting-package-options/create'
          },
          {
            element: <MeetingPackageOptionDetailPage />,
            path: 'meeting-package-options/:id'
          },
          {
            element: <UserPlanPrototypeListPage />,
            path: 'user-plan-prototypes/list'
          },
          {
            element: <UserPlanPrototypeDetail />,
            path: 'user-plan-prototypes/:id'
          },
          {
            element: <UserPlanPrototypeCreatePage />,
            path: 'user-plan-prototypes/create'
          },
          {
            element: <VerticalPlanPrototypesListPage />,
            path: 'vertical-plan-prototypes/list'
          },
          {
            element: <VerticalPlanPrototypeCreatePage />,
            path: 'vertical-plan-prototypes/create'
          },
          {
            element: <VerticalPlanPrototypeDetailPage />,
            path: 'vertical-plan-prototypes/:id'
          },
          {
            element: <DonationProductsListPage />,
            path: 'donation-products/list'
          },
          {
            element: <DonationProductDetailPage />,
            path: 'donation-products/:id'
          },
          {
            element: <DonationProductCreatePage />,
            path: 'donation-products/create'
          },
          {
            element: <MarketplaceProductGroupListPage />,
            path: 'marketplace-product-groups/list'
          },
          {
            element: <MarketplaceProductGroupDetailPage />,
            path: 'marketplace-product-groups/:id'
          },
          {
            element: <MarketplaceProductGroupCreatePage />,
            path: 'marketplace-product-groups/create'
          },
          {
            element: <AccrualOptionsListPage />,
            path: 'accrual-options/list'
          },
          {
            element: <AccrualOptionDetailPage />,
            path: 'accrual-options/:id'
          },
          {
            element: <AccrualOptionCreatePage />,
            path: 'accrual-options/create'
          },
          {
            element: <AccrualOptionGroupsListPage />,
            path: 'accrual-option-groups/list'
          },
          {
            element: <AccrualOptionGroupDetailPage />,
            path: 'accrual-option-groups/:id'
          },
          {
            element: <AccrualOptionGroupCreatePage />,
            path: 'accrual-option-groups/create'
          }
        ],
        path: 'plan'
      },
      {
        children: [
          { element: <ReconciliationListPage />, index: true, path: 'list' },
          { element: <ReconciliationDetailPage />, path: ':id' },
          { element: <ProviderCompensationListPage />, path: 'provider-compensations/list' },
          { element: <ProviderCompensationDetailPage />, path: 'provider-compensations/:id' },
          { element: <InvoiceListPage />, path: 'invoices/list' },
          { element: <InvoiceDetailPage />, path: 'invoices/:id' }
        ],
        path: 'reconciliation'
      },
      {
        children: [
          { element: <SponsorListPage />, index: true, path: 'list' },
          { element: <SponsorDetailPage />, path: ':id' }
        ],
        path: 'sponsor'
      },
      {
        children: [
          { element: <EventListPage />, index: true, path: 'list' },
          { element: <EventDetailPage />, path: ':id' },
          { element: <CreateEventPage />, path: 'create' },
          { element: <EventCopyDetailPage />, path: ':id/copy/:type' }
        ],
        path: 'event'
      },
      {
        children: [
          { element: <SponsorshipListPage />, index: true, path: 'list' },
          { element: <SponsorshipDetailPage />, path: ':id' },
          { element: <SponsorshipCreatePage />, path: 'create' }
        ],
        path: 'sponsorship'
      },
      {
        children: [
          { element: <RemoteConfigListPage />, index: true, path: 'list' },
          { element: <RemoteConfigDetailPage />, path: ':id' },
          { element: <RemoteConfigCreatePage />, path: 'create' },
          { element: <RemoteConfigAssignorListPage />, path: 'assignors/list' },
          { element: <RemoteConfigAssignorDetailPage />, path: 'assignors/:id' },
          { element: <RemoteConfigAssignorCreatePage />, path: 'assignors/create' }
        ],
        path: 'remoteConfig'
      }
    ],
    element: (
      <AuthGuard>
        <Suspense fallback={<Redirecting />}>
          <Outlet />
        </Suspense>
      </AuthGuard>
    )
  },
  {
    children: [{ element: <NotFoundView />, path: '404' }],
    element: (
      <Suspense fallback={<Redirecting />}>
        <Outlet />
      </Suspense>
    )
  }
];
